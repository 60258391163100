import {
  Avatar,
  Box,
  Button,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import RightArrow from "src/assets/svg-icons/admin/right-arrow.svg";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import websiteIcon from "src/assets/svg-icons/admin/website.svg";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { FormModeTypes } from "src/types/generic";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import InfoBox from "src/shared/components/info-box";
import {
  useAddPackageMutation,
  useGePackageById,
  useUpdatePackageMutation,
} from "src/api/cms";

const schema = yup.object().shape({
  name: yup.string().required("required"),
  price: yup.string().required("required"),
  description: yup.string().required("required"),
});

export interface FormFields extends yup.InferType<typeof schema> {}

const AddEditPackage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation("websiteCms");
  const { t: generalT } = useTranslation("general");
  const { t: customersT } = useTranslation("customers");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [active, setActive] = useState(true);
  const [hasItemsFeature, setHasItemsFeature] = useState(false);
  const [hasMatchingFeature, setHasMatchingFeature] = useState(false);
  const [hasAuditingFeature, setHasAuditingFeature] = useState(false);
  const mode: FormModeTypes = !!params.id ? "edit" : "add";

  // APIS
  const { mutate, isPending, status, error } = useAddPackageMutation();
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdatePackageMutation();
  const { data, refetch } = useGePackageById({
    enabled: !!params.id,
    id: params.id as string,
  });

  // react hook form
  const { control, formState, reset, handleSubmit } = useForm<FormFields>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const navigateToWebsiteCms = () => {
    navigate("/cms");
  };

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    console.log({ data });
    if (mode === "add") {
      mutate({
        ...data,
        price: +data.price,
        status: active,
        has_items: hasItemsFeature,
        has_matching: hasMatchingFeature,
        has_audit: hasAuditingFeature,
      });
    } else {
      mutateUpdate({
        id: params.id as string,
        ...data,
        price: +data.price,
        status: active,
        has_items: hasItemsFeature,
        has_matching: hasMatchingFeature,
        has_audit: hasAuditingFeature,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setActive(data.data.status);
      setHasItemsFeature(data.data.has_items);
      setHasMatchingFeature(data.data.has_matching);
      setHasAuditingFeature(data.data.has_audit);
      reset({
        name: data.data.name,
        price: data.data.price,
        description: data.data.description,
      });
    }
  }, [data]);

  //Add ..... success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
      navigate("/cms");
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update ..... success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
      navigate("/cms");
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToWebsiteCms}
          sx={{ cursor: "pointer" }}
        >
          {t("adminSystem")}
        </Typography>
        <img src={websiteIcon} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToWebsiteCms}
          sx={{ cursor: "pointer" }}
        >
          {t("websiteCms")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            id
          </Typography>
        </Box>
      </Stack>
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
        <Avatar sx={{ bgcolor: "#D0D5DD", mb: 1.5 }}>
          <img src={websiteIcon} width={"32px"} alt="" />
        </Avatar>
        <Typography fontSize={"32px"} fontWeight={400} lineHeight={"38.4px"}>
          {t("thirdEyePackage")}
        </Typography>
      </Box>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1.5} px={"180px"} alignContent={"center"}>
          {/* <InfoBox text={t("youCanNotEdit")} variant="warning" /> */}

          <Stack direction={"row"} spacing={1.5}>
            {/* package Name */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  autoFocus
                  fullWidth
                  size="small"
                  label={t("packageName")}
                  {...field}
                  error={!!formState.errors.name}
                  helperText={formState.errors.name?.message}
                  InputLabelProps={{
                    shrink: field.value !== undefined ? true : false,
                  }}
                />
              )}
            />
            {/* price */}
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  size="small"
                  label={t("priceMonthly")}
                  {...field}
                  error={!!formState.errors.price}
                  helperText={formState.errors.price?.message}
                  InputLabelProps={{
                    shrink: field.value !== undefined ? true : false,
                  }}
                />
              )}
            />
          </Stack>

          {/*  description */}
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                label={generalT("description")}
                {...field}
                error={!!formState.errors.description}
                helperText={formState.errors.description?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined ? true : false,
                }}
              />
            )}
          />

          {/*  yearly_discount */}
          {/* <Controller
            name="yearly_discount"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                size="small"
                label={t("yearlyDiscount")}
                {...field}
                error={!!formState.errors.yearly_discount}
                helperText={formState.errors.yearly_discount?.message}
                sx={{ bgcolor: "#FFFFFF" }}
              />
            )}
          /> */}

          <Stack direction={"row"} gap={8} pt={2}>
            <Box>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"16.8px"}
              >
                {t("activeInactive")}
              </Typography>
              <Switch
                checked={active}
                onChange={(e) => {
                  setActive(e.target.checked);
                }}
              />
            </Box>

            <Box>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"16.8px"}
              >
                {customersT("itemsFeature")}
              </Typography>
              <Switch
                checked={hasItemsFeature}
                onChange={(e) => {
                  setHasItemsFeature(e.target.checked);
                }}
              />
            </Box>

            <Box>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"16.8px"}
              >
                {customersT("matchingFeature")}
              </Typography>
              <Switch
                checked={hasMatchingFeature}
                onChange={(e) => {
                  setHasMatchingFeature(e.target.checked);
                }}
              />
            </Box>

            <Box>
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                lineHeight={"16.8px"}
              >
                {customersT("auditingFeature")}
              </Typography>
              <Switch
                checked={hasAuditingFeature}
                onChange={(e) => {
                  setHasAuditingFeature(e.target.checked);
                }}
              />
            </Box>
          </Stack>

          <Box display={"flex"} justifyContent={"end"} pt={4}>
            <Stack direction={"row"} spacing={1} width={489}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={navigateToWebsiteCms}
              >
                {generalT("cancel")}
              </Button>
              <LoadingButton
                loading={isPending || isPendingUpdate}
                variant="contained"
                fullWidth
                type="submit"
              >
                {generalT("save")}
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Stack>
  );
};

export default AddEditPackage;
